import Translate from '../components/Translate/Translate'

import iconPrimresim from '../assets/codePortfolioIcons/primresim/icon.png'
import mainPrimresim from '../assets/codePortfolioIcons/primresim/main.png'
import heroPrimresim from '../assets/codePortfolioIcons/primresim/hero.png'
import iphonePrimresim from '../assets/codePortfolioIcons/primresim/iphone.png'
import macbookPrimresim from '../assets/codePortfolioIcons/primresim/macbook.png'

import iconCBJ from '../assets/codePortfolioIcons/capturedbyjan/icon.png'
import mainCBJ from '../assets/codePortfolioIcons/capturedbyjan/main.png'
import heroCBJ from '../assets/codePortfolioIcons/capturedbyjan/hero.png'
import iphoneCBJ from '../assets/codePortfolioIcons/capturedbyjan/iphone.png'
import macbookCBJ from '../assets/codePortfolioIcons/capturedbyjan/macbook.png'

import iconFinancebay from '../assets/codePortfolioIcons/financebay/icon.png'
import mainFinancebay from '../assets/codePortfolioIcons/financebay/main.png'
import heroFinancebay from '../assets/codePortfolioIcons/financebay/hero.png' 
import macbookFinancebay from '../assets/codePortfolioIcons/financebay/macbook.png' 
import iphoneFinancebay from '../assets/codePortfolioIcons/financebay/iphone.png' 

import iconTyperka4 from '../assets/codePortfolioIcons/typerka-4/icon.png'
import mainTyperka4 from '../assets/codePortfolioIcons/typerka-4/main.png'
import heroTyperka4 from '../assets/codePortfolioIcons/typerka-4/hero.png'
import iphoneTyperka4 from '../assets/codePortfolioIcons/typerka-4/iphone.png'
import macbookTyperka4 from '../assets/codePortfolioIcons/typerka-4/macbook.png'

import iconTyperka3 from '../assets/codePortfolioIcons/typerka-3/icon.png'
import mainTyperka3 from '../assets/codePortfolioIcons/typerka-3/main.png'
import heroTyperka3 from '../assets/codePortfolioIcons/typerka-3/hero.png'
import iphoneTyperka3 from '../assets/codePortfolioIcons/typerka-3/iphone.png'
import macbookTyperka3 from '../assets/codePortfolioIcons/typerka-3/macbook.png'

import iconCryptofolio from '../assets/codePortfolioIcons/cryptofolio/icon.png'
import mainCryptofolio from '../assets/codePortfolioIcons/cryptofolio/main.png'
import heroCryptofolio from '../assets/codePortfolioIcons/cryptofolio/hero.png'
import iphoneCryptofolio from '../assets/codePortfolioIcons/cryptofolio/iphone.png'
import macbookCryptofolio from '../assets/codePortfolioIcons/cryptofolio/macbook.png'

import iconNeonKomunikator from '../assets/codePortfolioIcons/neon-komunikator/icon.png'
import mainNeonKomunikator from '../assets/codePortfolioIcons/neon-komunikator/main.png'
import heroNeonKomunikator from '../assets/codePortfolioIcons/neon-komunikator/hero.png'

import iconEatspace from '../assets/codePortfolioIcons/eatspace/icon.png'
import mainEatspace from '../assets/codePortfolioIcons/eatspace/main.png'
import heroEatspace from '../assets/codePortfolioIcons/eatspace/hero.png'
import iphoneEatspace from '../assets/codePortfolioIcons/eatspace/iphone.png'
import macbookEatspace from '../assets/codePortfolioIcons/eatspace/macbook.png'

import iconTradeBest from '../assets/codePortfolioIcons/tradebest/icon.png'
import mainTradeBest from '../assets/codePortfolioIcons/tradebest/main.png'
import heroTradeBest from '../assets/codePortfolioIcons/tradebest/hero.png'
import iphoneTradeBest from '../assets/codePortfolioIcons/tradebest/iphone.png'
import macbookTradeBest from '../assets/codePortfolioIcons/tradebest/macbook.png'

import iconEgera from '../assets/codePortfolioIcons/egera/icon.png'
import mainEgera from '../assets/codePortfolioIcons/egera/main.png'
import heroEgera from '../assets/codePortfolioIcons/egera/hero.png'

import iconPanelEgera from '../assets/codePortfolioIcons/egera-panel/icon.png'
import mainPanelEgera from '../assets/codePortfolioIcons/egera-panel/main.png'
import heroPanelEgera from '../assets/codePortfolioIcons/egera-panel/hero.png'

import iconFinancebay2 from '../assets/codePortfolioIcons/financebay-2/icon.png'
import mainFinancebay2 from '../assets/codePortfolioIcons/financebay-2/main.png'
import heroFinancebay2 from '../assets/codePortfolioIcons/financebay-2/hero.png'
import iphoneFinancebay2 from '../assets/codePortfolioIcons/financebay-2/iphone.png'
import macbookFinancebay2 from '../assets/codePortfolioIcons/financebay-2/macbook.png'
import galleryFinancebay1 from '../assets/codePortfolioIcons/financebay-2/gallery/1.png'
import galleryFinancebay2 from '../assets/codePortfolioIcons/financebay-2/gallery/2.png'
import galleryFinancebay3 from '../assets/codePortfolioIcons/financebay-2/gallery/3.png'
import galleryFinancebay4 from '../assets/codePortfolioIcons/financebay-2/gallery/4.png'
import galleryFinancebay5 from '../assets/codePortfolioIcons/financebay-2/gallery/5.png'
import galleryFinancebay6 from '../assets/codePortfolioIcons/financebay-2/gallery/6.png'
import galleryFinancebay7 from '../assets/codePortfolioIcons/financebay-2/gallery/7.png'

import iconMovieMate from '../assets/codePortfolioIcons/moviemate/icon.png'
import mainMovieMate from '../assets/codePortfolioIcons/moviemate/hero.png'
import heroMovieMate from '../assets/codePortfolioIcons/moviemate/hero.png'
import iphoneMovieMate from '../assets/codePortfolioIcons/moviemate/iphone-1.png'
import macbookMovieMate from '../assets/codePortfolioIcons/moviemate/iphone-2.png'

import iconBitomatATM from '../assets/codePortfolioIcons/bitomat-atm/icon.png'
// import mainFinancebay2 from '../assets/codePortfolioIcons/financebay-2/main.png'
import heroBitomatATM from '../assets/codePortfolioIcons/bitomat-atm/hero.png'

import iconStylNegocjacji from '../assets/codePortfolioIcons/styl-prowadzenia-negocjacji/icon.png'
// import mainFinancebay2 from '../assets/codePortfolioIcons/financebay-2/main.png'
import heroStylNegocjacji from '../assets/codePortfolioIcons/styl-prowadzenia-negocjacji/hero.png'

import iconEgeraTranslator from '../assets/codePortfolioIcons/egera-translator/icon.png'
// import mainFinancebay2 from '../assets/codePortfolioIcons/financebay-2/main.png'
import heroEgeraTranslator from '../assets/codePortfolioIcons/egera-translator/hero.png'

import iconGlodnySkaner from '../assets/codePortfolioIcons/glodny-skaner/icon.png'
// import mainFinancebay2 from '../assets/codePortfolioIcons/financebay-2/main.png'
import heroGlodnySkaner from '../assets/codePortfolioIcons/glodny-skaner/hero.png'

import iconEgeraBitomat from '../assets/codePortfolioIcons/egera-bitomat/icon.png'
// import mainFinancebay2 from '../assets/codePortfolioIcons/financebay-2/main.png'
import heroEgeraBitomat from '../assets/codePortfolioIcons/egera-bitomat/hero.png'

import iconPanelOzeSystem from '../assets/codePortfolioIcons/panel-oze-system/icon.png'
// import mainFinancebay2 from '../assets/codePortfolioIcons/financebay-2/main.png'
import heroPanelOzeSystem from '../assets/codePortfolioIcons/panel-oze-system/hero.png'

import iconEgeraExchange from '../assets/codePortfolioIcons/egera-exchange/icon.png'
// import mainFinancebay2 from '../assets/codePortfolioIcons/financebay-2/main.png'
import heroEgeraExchange from '../assets/codePortfolioIcons/egera-exchange/hero.png'

import iconHouseOfCats from '../assets/codePortfolioIcons/house-of-cats/icon.png'
// import mainFinancebay2 from '../assets/codePortfolioIcons/financebay-2/main.png'
import heroHouseOfCats from '../assets/codePortfolioIcons/house-of-cats/hero.png'

const newCodePortfolio = [
    {
        bigName: 'CapturedByJan',
        smallName: 'photography portfolio',
        icon: iconCBJ,
        mainImg: mainCBJ,
        heroImg: heroCBJ,
        iphoneImg: iphoneCBJ,
        macbookImg: macbookCBJ,
        p1: "Prywatne portfolio fotograficzne zawierające zbiór zdjęć i filmów. Strona posiada system zarządzania treścią oparty o Sanity.io, system e-commerce zintegrowany z płatnościami Stripe. Aplikacja maksymalnie zoptymalizowana pod kątem dostępności i szybkości działania na wszystkich rodzajach urządzeń.",
        p2: "Strona posiada autorski system CMS do łatwego zarządzania bazą danych zdjęć oraz autorski system ecommerce, dzięki któremu wprowadzony został sklep online. Użytkownik ma możliwość przeglądania wszystkich zdjęc na dynamicznej mapie z pinezkami, szczegółów poszczególnych zdjęc takich jak: miejsc oraz czas wykonania, parametry fotografii, warunki pogodowe.",
        availableOn: ['mobile', 'tablet', 'laptop', 'web', 'tv'],
        feautures: [
            'CMS system',
            'e-commerce system',
            'stripe payments',
            'dynamic map with pins',
            'sending emails',
            'full RWD',
        ],
        slug: 'capturedbyjan',
        stack: ['React.js', 'LeafletMap', 'Next.js', 'Sanity.io', 'Stripe', 'Tailwind.css'],
        url: 'https://capturedbyjan.art',
        isLive: true,
    },
    {
        bigName: 'FinanceBay 2.0',
        smallName: 'expense journal',
        icon: iconFinancebay2,
        mainImg: mainFinancebay2,
        heroImg: heroFinancebay2,
        iphoneImg: iphoneFinancebay2,
        macbookImg: macbookFinancebay2,
        availableOn: ['mobile', 'tablet', 'laptop','web'],
        slug: 'financebay-2.0',
        p1: "Web and mobile application that allows you to track your private budget. The user can register their current expenses, income, overdue payments, automatically checked recurring payments, e.g. for subscriptions to other applications such as Netflix or Spotify.",
        p2: "In addition, after registration, the user has the ability to track their savings by creating personal piggy banks, analyze expenses over time and by category. There is also an option to track your investments in cryptocurrencies, thanks to automatic download of current exchange rates, we can analyze total profits and losses in a transparent way.",
        feautures: [
            'dynamic charts',
            'fetching currency rates api',
            'auth system',
            'dynamic database',
            'own api',
            'full RWD',
            'PWA'
        ],
        stack: ['Next.js', 'Supabase', 'Tailwind.css', 'Tailwind UI', 'Chart.js', 'Auth.js', 'Prisma', 'CoinMarketCap API'],
        url: 'https://finance-bay-2-0.vercel.app',
        isLive: true,
        gallery: [
            galleryFinancebay1,
            galleryFinancebay2,
            galleryFinancebay3,
            galleryFinancebay4,
            galleryFinancebay5,
            galleryFinancebay6,
            galleryFinancebay7,
        ]
    },
    {
        icon: iconMovieMate,
        heroImg: heroMovieMate,
        iphoneImg: iphoneMovieMate,
        macbookImg: macbookMovieMate,
        bigName: 'MovieMate',
        smallName: 'movie recommend app',
        availableOn: ['mobile', 'tablet'],
        p1: "Aplikacja przystosowana na smartfony i tablety. Możliwość rejestracji konta użytkownika i dodawania przyjaciół do znajomych. Użytkownik ma możliwość wyszukania w dynamicznej wyszukiwarce filmu lub serialu, a następnie sprawdzenia wielu przydatnych informacji takich jak: opis, gatunek, rok produkcji, zwiastun, platformy streamingowe, na których może znaleźć materiał.",
        p2: "Główną funkcjonalnością aplikacji jest możlwość szybkiego i łatwego wysłania rekomendacji do wcześniej dodanego znajomego. Użytkownik również ma możliwość dodania filmu bądź serialu do polubionych, obejrzanych lub oznaczyć go jako 'do obejrzenia'. W prosty i czytelny sposób mamy możliwość przeglądania rekomendacji, które wysłaliśmy do znajomych i tych, które oni wysłali nam.",
        slug: 'moviemate',
        feautures: [
            'own api',
            'auth system',
            'dynamic database',
            'friendship system',
            'messaging system',
            'animations',
            'PWA'
        ],
        stack: ['Next.js', 'Supabase', 'Auth.js', 'Tailwind.css', 'Tailwind UI', 'Framer Motion', 'TMDB API', 'Prisma'],
        url: 'https://movie--mate.vercel.app',
        isLive: true,
    },
    {
        icon: iconPrimresim,
        mainImg: mainPrimresim,
        heroImg: heroPrimresim,
        iphoneImg: iphonePrimresim,
        macbookImg: macbookPrimresim,
        bigName: 'Primresim',
        smallName: 'e-commerce',
        availableOn: ['mobile', 'tablet', 'laptop', 'web'],
        p1: "Nowoczesny sklep internetowy, napisany od zera przy użyciu NextJS, zintegrowaniu systemu płatności Stripe oraz zaprojektowanej bazy przy pomocy PayloadCMS. Stworzony został panel administratora sklepu, z którego w łatwy sposób może edytować dostępne produkty jak i zarządzać zamówieniami.",
        p2: "Klient ma możliwość przeglądania produktów w różych kategoriach, wybrać przy składaniu zamówienia rozmiar produktu oraz jego kolor. Napisano system mailngowy, który informuje kupujących o statusie zamówienia i płatności.",
        slug: 'primresim',
        feautures: [
            'auth system',
            'webhooks',
            'own api', 
            'e-commerce system',
            'payment system',
            'full RWD',
            'admin panel',
            'mailing system'
        ],
        stack: ['Next.js', 'Firebase', 'Tailwind.css', 'Framer Motion', 'Stripe', 'Resend', 'Payload CMS'],
        isLive: false,
        url: '',
    },
    {
        icon: iconTyperka3,
        bigName: 'Typerka 3.0',
        smallName: 'mobile game',
        mainImg: mainTyperka3,
        heroImg: heroTyperka3,
        iphoneImg: iphoneTyperka3,
        macbookImg: macbookTyperka3,
        feautures: [
            'auth system',
            'PWA',
            'admin panel',
            'own api', 
            'full RWD',
            'mailing system'
        ],
        p1: 'Gra mobilna stworzona na okres trwania rozgrywek Mistrzostw Świata w piłce nożnej w Katarze w 2022 roku. Gracze po rejestracji mają możliwość wytypowania dokładnego wyniku każdego spotkania. Po rozegraniu meczu dostają odpowiednią liczbę punktów: 3, 1 lub 0. Rywalizują oni w swoich pokojach z innymi graczami, którzy do nich dołączyli.',
        p2: 'Napisane od zera autorskie API, przy pomocy dostawcy API-Football, pozwala na pobieranie wyników meczy w czasie rzeczywistym, system gry jest w pełni zautomatyzowany oraz dostosowany do dostępności na każdym możliwym urządzeniu. Zastosowano również system mailngowy do komunikacji z graczami.',
        availableOn: ['mobile', 'tablet',  'laptop', 'web'],
        isLive: false,
        url: '',
        stack: ['Next.js', 'Node.js', 'Firebase', 'Bootstrap', 'Chart.js', 'Nodemailer', 'API Football'],
        slug: 'typerka-3.0'
    },
    {
        icon: iconFinancebay,
        mainImg: mainFinancebay,
        heroImg: heroFinancebay,
        iphoneImg: iphoneFinancebay,
        macbookImg: macbookFinancebay,
        bigName: 'FinanceBay',
        smallName: 'finances assistant',
        availableOn: ['mobile', 'tablet', 'laptop', 'web'],
        feautures: [
            'auth system',
            'PWA',
            'full RWD',
            'dynamic calendars',
            'dynamic charts',
            'admin panel',
        ],
        isLive: false,
        p1: "Pierwsza wersja aplikacji FinanceBay, napisana w czystym React.js, przy użyciu sporej ilości innych bibliotek. Autoryzacja jak i baza danych oparta została na systemie Firebase od Google.",
        p2: 'Możliwość rejestrowania wydatków, cyklicznych płatności, analizowania wszystkich danych z podziałem na kategorie, przeglądania historycznych transakcji.',
        url: '',
        stack: ['React.js', 'Node.js', 'Firebase', 'Bootstrap', 'Chart.js', 'Nodemailer'],
        slug: 'financebay'
    },
    {
        icon: iconTyperka4,
        mainImg: mainTyperka4,
        heroImg: heroTyperka4,
        bigName: 'Typerka 4.0',
        smallName: 'mobile game',
        iphoneImg: iphoneTyperka4,
        macbookImg: macbookTyperka4,
        feautures: [
            'Google Auth',
            'PWA',
            'own api', 
            'full RWD',
            'mailing system',
            'notification system',
            'admin panel',
        ],
        p1: 'Kolejna odsłona gry mobilnej Typerka, stworzonej na okres trwania rozgrywek Euro 2024 w piłce nożnej w Niemczech w 2024 roku. Gracze po rejestracji mają możliwość wytypowania dokładnego wyniku każdego spotkania. Po rozegraniu meczu dostają odpowiednią liczbę punktów: 3, 1 lub 0. Użytkownicy mają możliwość sprawdzenia w czasie rzeczywistym wyników meczy oraz typy spotkania swoich przeciwników. Łącznie podczas rywalizacji z aplikacji korzystało 52 graczy, nie wykryto żadnych problemów podczas całości turnieju.',
        p2: 'Napisane od zera autorskie API, przy pomocy dostawcy API-Football, pozwala na pobieranie wyników meczy w czasie rzeczywistym oraz aktualizacje rangingu graczy, system gry jest w pełni zautomatyzowany oraz dostosowany do dostępności na każdym możliwym urządzeniu. Zastosowano również system mailngowy do komunikacji z graczami.',
        availableOn: ['mobile', 'tablet',  'laptop', 'web'],
        isLive: false,
        url: '',
        stack: ['Next.js', 'Node.js', 'Supabase', 'Tailwind.css', 'Tailwind UI', 'Kinde', 'Nodemailer', 'API Football'],
        slug: 'typerka-4.0'
    },
    {
        icon: iconTradeBest,
        bigName: 'TradeBest',
        smallName: 'real estate service',
        mainImg: mainTradeBest,
        heroImg: heroTradeBest,
        iphoneImg: iphoneTradeBest,
        macbookImg: macbookTradeBest,
        feautures: [
            'auth system',
            'PWA',
            'own api', 
            'full RWD',
            'admin panel',
        ],
        p1: 'Napisany od zera portal do zamieszczania ogłoszeń nieruchomości, domów, mieszkań, działek - na wynajem lub sprzedaż. Możliwość rejestracji konta klienta, z którego ma on możliwość dodania swojego ogłoszenia, które musi zostać najpierw sprawdzone przez administratora serwisu, przed publikacją na stronie głównej, widocznej dla każdego odwiedzającego portal.',
        p2: "Podczas przeglądania dostępnych ogłoszeń, klienta ma możliwość szukania po województwie, powiecie bądź dzielnicy, filtrować ze względu na cenę, cechy obiektu bądź jego dane deweloperskie. Podczas dodawania ogłoszenia użytkownik ma możliwość do wybrania lokalizacji z dynamicznej mapki, wgrania zdjęć obiektu.",
        availableOn: ['mobile', 'tablet',  'laptop', 'web'],
        isLive: false,
        url: '',
        stack: ['Next.js', 'Firebase', 'Tailwind.css', 'Tailwind UI', 'LeafletMap'],
        slug: 'tradebest'
    },
    {
        icon: iconEatspace,
        bigName: 'Eatspace',
        smallName: 'restaurant system',
        mainImg: mainEatspace,
        heroImg: heroEatspace,
        iphoneImg: iphoneEatspace,
        macbookImg: macbookEatspace,
        feautures: [
            'auth system',
            'Google Auth',
            'PWA',
            'own api', 
            'full RWD',
            'admin panel',
        ],
        p1: 'Aplikacja stworzona podczas obrony tytułu inżynierskiego podczas studiów. System przeznaczony dla pracowników gastronomii jak i ich klientów. Właściciel restauracji po rejestracji konta i dodaniu swojego lokalu, ma możliwość dodania informacji o swoim miejscu, uzupełnienia dostępnego menu jak i dokładnego przeniesienia planu swojej restauracji do systemu dzięki autorskiemu skryptowai do edycji planu lokalu w 2D.',
        p2: "Użytkownik portalu (klient restauracji), ma możliwość złożenia rezerwacji z wskazaniem na dynamicznej mapie 2D lokalu, konkretnego miejsca, w którym chce usiąść, opcjonalne zamówienia z góry pozycji z menu, wybranie stylu obsługi przez kelnera bądź też innych szczegółów wizyty w lokalu. Właściciel restauracji w podglądzie swojego lokalu ma możliwość zarządzania wszystkimi rezerwacjami oraz wygodnego ich przeglądania w autorskim panelu.",
        availableOn: ['mobile', 'tablet',  'laptop', 'web'],
        isLive: false,
        url: '',
        stack: ['Next.js', 'Firebase', 'Framer Motion', 'Nodemailer', 'LeafletMap'],
        slug: 'eatspace'
    },
    {
        icon: iconCryptofolio,
        mainImg: mainCryptofolio,
        heroImg: heroCryptofolio,
        bigName: 'CryptoFolio',
        smallName: 'cryptocurrency manager',
        iphoneImg: iphoneCryptofolio,
        macbookImg: macbookCryptofolio,
        feautures: [
            'auth system',
            'Google Auth',
            'PWA',
            'own api', 
            'full RWD',
            'admin panel',
            'dynamic crypto rates',
            'notifications'
        ],
        // p1: 'Aplikacja stworzona podczas obrony tytułu inżynierskiego podczas studiów. System przeznaczony dla pracowników gastronomii jak i ich klientów. Właściciel restauracji po rejestracji konta i dodaniu swojego lokalu, ma możliwość dodania informacji o swoim miejscu, uzupełnienia dostępnego menu jak i dokładnego przeniesienia planu swojej restauracji do systemu dzięki autorskiemu skryptowai do edycji planu lokalu w 2D.',
        // p2: "Użytkownik portalu (klient restauracji), ma możliwość złożenia rezerwacji z wskazaniem na dynamicznej mapie 2D lokalu, konkretnego miejsca, w którym chce usiąść, opcjonalne zamówienia z góry pozycji z menu, wybranie stylu obsługi przez kelnera bądź też innych szczegółów wizyty w lokalu. Właściciel restauracji w podglądzie swojego lokalu ma możliwość zarządzania wszystkimi rezerwacjami oraz wygodnego ich przeglądania w autorskim panelu.",
        availableOn: ['mobile', 'tablet',  'laptop', 'web'],
        isLive: false,
        url: '',
        stack: ['React.js', 'Firebase', 'Bootstrap', 'Framer Motion', 'Nodemailer', 'Chart.js', 'Coingecko API'],
        slug: 'cryptofolio'
    },
    {
        icon: iconPanelOzeSystem,
        heroImg: heroPanelOzeSystem,
        bigName: 'Panel OZE-System',
        smallName: 'merchant panel',
        iphoneImg: iphoneCryptofolio,
        macbookImg: macbookCryptofolio,
        feautures: [
            'auth system',
            'PWA',
            'own api', 
            'full RWD',
            'admin panel',
            'CRM system',
            'generating PDF',
            'notifications'
        ],
        // p1: 'Aplikacja stworzona podczas obrony tytułu inżynierskiego podczas studiów. System przeznaczony dla pracowników gastronomii jak i ich klientów. Właściciel restauracji po rejestracji konta i dodaniu swojego lokalu, ma możliwość dodania informacji o swoim miejscu, uzupełnienia dostępnego menu jak i dokładnego przeniesienia planu swojej restauracji do systemu dzięki autorskiemu skryptowai do edycji planu lokalu w 2D.',
        // p2: "Użytkownik portalu (klient restauracji), ma możliwość złożenia rezerwacji z wskazaniem na dynamicznej mapie 2D lokalu, konkretnego miejsca, w którym chce usiąść, opcjonalne zamówienia z góry pozycji z menu, wybranie stylu obsługi przez kelnera bądź też innych szczegółów wizyty w lokalu. Właściciel restauracji w podglądzie swojego lokalu ma możliwość zarządzania wszystkimi rezerwacjami oraz wygodnego ich przeglądania w autorskim panelu.",
        availableOn: ['mobile', 'tablet',  'laptop', 'web'],
        isLive: false,
        url: '',
        stack: ['Next.js', 'Supabase', 'Kinde', 'Tailwind.css', 'Tailwind UI', 'Framer Motion', 'Chart.js'],
        slug: 'panel-oze-system'
    },
    {
        icon: iconHouseOfCats,
        heroImg: heroHouseOfCats,
        bigName: 'House of Cats',
        smallName: 'roommate app',
        availableOn: ['web', 'mobile', 'tablet'],
        // slug: 'house-of-cats'
    },
    {
        icon: iconEgera,
        bigName: 'Egera',
        smallName: 'website',
        mainImg: mainEgera,
        heroImg: heroEgera,
        availableOn: ['web', 'mobile', 'tablet'],
        // slug: 'egera'
    },
    {
        icon: iconGlodnySkaner,
        bigName: 'Głodny Skaner',
        heroImg: heroGlodnySkaner,
        smallName: 'app of restaurants',
        availableOn: ['web', 'mobile', 'tablet'],
        // slug: 'glodny-skaner'
    },
    {
        icon: iconNeonKomunikator,
        bigName: 'NeonKomunikator',
        mainImg: mainNeonKomunikator,
        heroImg: heroNeonKomunikator,
        smallName: 'text messenger',
        availableOn: ['web', 'mobile', 'tablet'],
        // slug: 'neon-komunikator'
    },
    {
        icon: iconPanelEgera,
        bigName: 'Panel Egera',
        mainImg: mainPanelEgera,
        heroImg: heroPanelEgera,
        smallName: 'user panel',
        availableOn: ['web', 'mobile', 'tablet'],
        // slug: 'panel-egera'
    },
    {
        icon: iconBitomatATM,
        bigName: 'Bitomat ATM',
        heroImg: heroBitomatATM,
        smallName: 'bitcoin atm ui',
        availableOn: ['web', 'mobile', 'tablet'],
        // slug: 'bitomat-atm'
    },
    {
        icon: iconEgeraBitomat,
        bigName: 'Bitomat Egera',
        heroImg: heroEgeraBitomat,
        smallName: 'website',
        availableOn: ['web', 'mobile', 'tablet'],
        // slug: 'egera-bitomat'
    },
    {
        icon: iconStylNegocjacji,
        bigName: 'Test of T.Kilmann\'s',
        heroImg: heroStylNegocjacji,
        smallName: 'interactive test',
        availableOn: ['web', 'mobile', 'tablet'],
        // slug: 'styl-prowadzenia-negocjacji'
    },
    {
        icon: iconEgeraTranslator,
        bigName: 'Egera Translator',
        heroImg: heroEgeraTranslator,
        smallName: 'translator',
        availableOn: ['web', 'mobile', 'tablet'],
        // slug: 'translator'
    },
    {
        icon: iconEgeraExchange,
        bigName: 'Egera Exchange',
        heroImg: heroEgeraExchange,
        smallName: 'cryptocurrency exchange',
        availableOn: ['web', 'mobile', 'tablet'],
        // slug: 'egera-exchange'
    },
]

export default newCodePortfolio