import HistoryEducation from '../../components/HistoryEducation/HistoryEducation';
import HistoryWork from '../../components/HistoryWork/HistoryWork';
import Title from '../../components/Title/Title';
import Certificates from '../../components/Certificates/Certificates';
import { LangContext } from '../../App'
import { useContext } from 'react';
import style from './Experience.module.css'
import Translate from '../../components/Translate/Translate';
import React from 'react';

function Experience(){
    const historyWork = [
        {
            title: "Frontend Developer & UI/UX Designer",
            subtitle: <Translate>cryptocurrency exchange</Translate>,
            position: <Translate>Programming the visual part of the Egera cryptocurrency exchange, the user panel and the company's main website using languages ​​such as: HTML, CSS, PHP, JavaScript, jQuery. Creating advertising graphics and designing application interfaces.</Translate>,
            years: <Translate>June 2021 - Now</Translate>,
            fromTimestamp: 1622534400000,
            groupBy: 'years',
            toTimestamp: null,
            icon: "egera"
        },
        {
            title: <Translate>Graphic designer</Translate>,
            subtitle: <Translate>programming science club</Translate>,
            position: <Translate>Designing graphical interfaces, icons, animations and game elements for the High Score Games student science club at the WSB University in Poznań, dealing with the creation of computer games.</Translate>,
            years: <Translate>October 2020 - November 2021</Translate>,
            fromTimestamp: 1601539200000,
            toTimestamp: 1635757200000,
            groupBy: 'months',
            icon: "highScoreGames"
        },
        {
            title: <Translate>IT Support and Graphic Designer</Translate>,
            subtitle: <Translate>computer game publisher</Translate>,
            position: <Translate>Administering the website of the PixelsInteractive studio that creates computer games. Creating pages and subpages and making corrections to them, modernizing them and adding new functionalities.</Translate>,
            years:<Translate>April 2020 - September 2020</Translate>,
            fromTimestamp: 1585728000000,
            toTimestamp: 1598947200000,
            groupBy: 'months',
            icon: "pixelsInteractive"
        },
        {
            title: <Translate>Programmer and Administrator of websites</Translate>,
            subtitle: <Translate>internship, robotics</Translate>,
            position: <Translate>Installation, configuration and administration of websites based on the Wordpress CMS system and Moodle elearning platform at MalagaByte company located in Malaga, Spain. Searching for errors in the code and applying corrections.</Translate>,
            years: <Translate>November 2018 - December 2018</Translate>,
            fromTimestamp: 1541062800000,
            toTimestamp: 1543654800000,
            groupBy: 'months',
            icon: "malagaByte"
        },
        {
            title: <Translate>Graphic designer and web developer</Translate>,
            subtitle: <Translate>social media</Translate>,
            position: <Translate>Creating graphics for social media and marketing materials, working with vector and raster graphics. Design and coding of a landing page, one page, administration of a Wordpress-based website.</Translate>,
            years: <Translate>July 2017 - November 2021</Translate>,
            fromTimestamp: 1498896000000,
            toTimestamp: 1635757200000,
            groupBy: 'years',
            icon: "work3"
        },
    ]

    const historyEdu = [
        {
            title: <Translate>University WSB Merito in Poznań, Poland</Translate>,
            subtitle: false,
            position: <Translate>Studia magisterskie na kierunku Zarządzanie ze specjalizacją Junior Project Manager</Translate>,
            years: <Translate>2024 - now</Translate>,
            icon: "edu3",
            fromTimestamp: 1727794013000,
            toTimestamp: new Date().valueOf(),
            groupBy: 'months'
        },
        {
            title: <Translate>University WSB Merito in Poznań, Poland</Translate>,
            subtitle: false,
            position: <Translate>Engineering studies in the field of computer science with a specialization in application programming</Translate>,
            years: <Translate>2020 - 2024</Translate>,
            icon: "edu1",
            fromTimestamp: 1601539200000,
            toTimestamp: 1731251787000,
            groupBy: 'years'
        },
        {
            title: <Translate>Communications School Complex in Poznań, Poland</Translate>,
            subtitle: false,
            position: <Translate>IT technician specialization.</Translate>,
            years: "2016 - 2020",
            icon: "edu2",
            fromTimestamp: 1472716800000,
            toTimestamp: 1601539200000,
            groupBy: 'years'
        }
    ]

    const certificates = [
        {
            title: <Translate>E.12 Qualification</Translate>,
            desc: <Translate>Installation and operation of personal computers and peripheral devices</Translate>,
            date: <Translate>January 2018</Translate>,
            icon: "pc",
            from: "CKE"
        },
        {
            title: <Translate>E.13 Qualification</Translate>,
            desc: <Translate>Designing local computer networks and network administration</Translate>,
            date: <Translate>June 2019</Translate>,
            icon: "net",
            from: "CKE"
        },
        {
            title: <Translate>E.14 Qualification</Translate>,
            desc: <Translate>Creating web applications and databases and administering databases</Translate>,
            date: <Translate>June 2020</Translate>,
            icon: "code",
            from: "CKE"
        },
    ]

    const references = [
        {
            who: "Paweł Borycki",
            position: "Sales Director Grupa dlastudenta.pl",
            content: "Współpraca opierała się stworzeniu kilku kreacji graficznych oraz dedykowanego landing page. Projekty wykonane były profesjonalnie, a same koncepcje i pomysły dla ich stworzenia współgrały z naszymi oczekiwaniami. Dużym plusem we współpracy była także bezproblemowa komunikacja, gdzie odpowiedzi na nasze pytania spływały w bardzo szybkim czasie. Dlatego możemy polecić pana Magera jako Partnera do realizacji własnych projektów"
        },
        {
            who: "Firma Happy Home",
            position: "",
            content: "W czerwcu 2017 miałem przyjemność współpracować z Panem Janem Mager. Zdecydowałem się po przejrzeniu jego dotychczasowych prac oraz przystepnej cenie. Muszę przyznać że podszedł do powierzonego zadania profesjonalnie- przedstawiając kilka projektów. Wszystkie były wykonane według moich wskazań i w dość krótkim czasie. Serdecznie polecam!"
        },
        {
            who: "Konrad Gandera",
            position: "Twórca praktykbiznesusieciowego.pl",
            content: "Świetny, kreatywny grafik. Szybko i zawsze na czas. Atrakcyjny cenowo na rynku. Szczerze polecam!"
        },
        {
            who: "Adam Tarunt",
            position: "Praktyk Social Media",
            content: "Janek to osoba, którą kiedyś polecił mi pewien networker. Powiedział wtedy, że dobry człowiek, student na dorobku, ale przede wszystkim twórca świetnych stron www, różnych grafik i projektów. To okazało się prawdą, co widać na załączonym obrazku. Jeśli szukasz człowieka, który nie narzeka na zwracanie uwagi, czasami na zawracanie głowy absolutnie nie reaguje tylko działa z tym co chcesz aby było wykonane. Dodatkowo widzi więcej niż klasyczny grafik, informatyk, czy inny programista. Jest kreatywną osobą i pomaga przy tworzeniu. Z całego serca polecam współpracę z Janem Mager i dziękuję."
        },
    ]

    const langCurrent = useContext(LangContext)

    return(
        <div>
            <HistoryWork history={historyWork} />
            <HistoryEducation history={historyEdu} />
            <Title text={<Translate>Certificates</Translate>} />
            <Certificates certificates={certificates}/>
            {/* <Title text="Autorskie kursy" />
            <Tutorials tutorials={tutorials}/> */}
            {/* { 
                langCurrent == 'pl' &&
                <div className='hide-mobile'>
                    <Title text="Polecenia od klientów" />
                    <References references={references} />
                </div>
            } */}
            
        </div>
    )
}

export default Experience
