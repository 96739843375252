import React, { useEffect, useState } from 'react';
import style from './Sidebar.module.css'
import profileImage from '../../assets/profile.jpg';
import ProfileInformation from '../ProfileInformation/ProfileInformation';
import Button from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons'

import Translate from '../Translate/Translate'

function Sidebar(){
    const [age, setAge] = useState()
    const [showInfos, setShowInfos] = useState('')
    const [showInfosLabel, setShowInfosLabel] = useState('')

    function showMoreInfo(){
        if(showInfos === 'flex'){
            setShowInfos('none');
        }
        else{
            setShowInfos('flex');
        }
    }
    
    useEffect(() => {
        let date = new Date();
        setAge(date.getFullYear() - 2000);
    }, [])

    useEffect(() => {
        if(showInfos == 'flex') showMoreInfo()
    }, [window.location.href])

    return(
        <div className={style.sidebar}>
            <div className={style.nameAndPostion}>
                <div className={style.nameAndPostionInside}>
                    <span className={style.name}>Jan Mager</span>
                    <span className={style.position}>Fullstack Developer & UI Designer</span>
                </div>
                <div className={style.showMore} onClick={showMoreInfo}>
                    {
                        showInfos === 'flex' ? <FontAwesomeIcon icon={faAngleUp} /> : <FontAwesomeIcon icon={faAngleDown} />
                    }
                </div>
            </div>
            <div className={style.more} style={{display: showInfos}}>
                <div className={style.profileImage}>
                    <img src={profileImage} alt="Profile Image" />
                </div>
                <div className={style.profileInformations}>
                    <ProfileInformation label={<Translate>Name</Translate>} content="Jan" />
                    <ProfileInformation label={<Translate>Surname</Translate>} content="Mager" />
                    <ProfileInformation label={<Translate>Languages</Translate>} content={<Translate>polish, english</Translate>} />
                    <ProfileInformation label={<Translate>Education</Translate>} content={<Translate>IT Engineer</Translate>} />
                </div>
                {/* <Button text={<Translate>Download my CV</Translate>} link="#" /> */}
            </div>
        </div>
    )
}

export default Sidebar;
