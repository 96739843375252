import { useEffect, useState } from 'react';
import Translate from '../../components/Translate/Translate';
import newCodePortfolio from '../../data/newCodePortfolio';
import { useParams } from 'react-router-dom';
import React from 'react';
import SingleCodeSidebar from '../../components/SingleCodeSidebar/SingleCodeSidebar';
import style from './SingleCode.module.css'
import { NavLink } from 'react-router-dom';
import { MdKeyboardDoubleArrowRight } from "react-icons/md";
import { IoMdClose } from 'react-icons/io';

function SingleCode(){
    const [ currentCode, setCurrentCode ] = useState()
    const slug = useParams()
    const [ show, setShow ] = useState()

    const [ zoomImage, setZoomImage ] = useState()

    useEffect(() => {
        let obj = newCodePortfolio.find(code => code.slug == slug.slug)
        if(obj) setCurrentCode(obj)
    }, [])

    if(currentCode) return(
        <div className={style.singlecodeOverlay}>
            {
                zoomImage &&
                <div style={{zIndex: 50, position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <img src={zoomImage} width={'80%'} style={{zIndex: 50}} />
                    <div style={{background: 'rgba(0,0,0,0.75)', backdropFilter: 'blur(2px)', zIndex: 40, cursor: 'pointer', position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}} onClick={() => setZoomImage('')}></div>
                    <div style={{zIndex: 50, position: 'fixed', top: 15, right: 20, color: 'white', cursor: 'pointer'}} onClick={() => setZoomImage('')}><IoMdClose style={{fontSize: '30px'}} /></div>
                </div>
            }
            <SingleCodeSidebar item={currentCode} />
            <div className={style.mainBoxOverlay}>
                <div className={style.crumb}>
                    <NavLink to="/">Portfolio</NavLink>
                    <span style={{fontSize: '14px', opacity: .15}}><MdKeyboardDoubleArrowRight  style={{marginBottom: '3px'}} /></span>
                    <p><Translate>{currentCode.bigName}</Translate></p>
                </div>
                <img className={style.icon} src={currentCode.icon} height={48} width={48} />
                <h1><Translate>{currentCode.bigName}</Translate></h1>
                <span><Translate>{currentCode.smallName}</Translate></span>
                <div className={style.mainImg}>
                    <img src={currentCode.mainImg} />
                    <div></div>
                </div>
                {currentCode.p1 ? 
                <div className={style.contentBlock} style={{marginTop: currentCode.mainImg ? '-75px' : ''}}>
                    <img src={currentCode.iphoneImg} />
                    <p style={{fontSize: '14px'}}>
                        <Translate>{currentCode.p1}</Translate>
                    </p>
                </div> : ''}
                {currentCode.p2 ? 
                <div className={`${style.contentBlock} ${style.contentBlock2}`} style={{marginTop: '25px'}}>
                    <p style={{fontSize: '14px'}}>
                        <Translate>{currentCode.p2}</Translate>
                    </p>
                    <img src={currentCode.macbookImg} style={{maxWidth: currentCode.mainImg ? '500px' : '300px'}} />
                </div> : ''}
                {currentCode.gallery && currentCode.gallery.length > 0 && <div className={style.gallery_box}>
                    <span className={style.gallery_header}>
                        <Translate>Application views</Translate>
                    </span>
                    <div className="container mx-0 mt-2">
                        <div className="row">
                        {
                            currentCode.gallery.map((img) => {
                                return (
                                    <div onClick={() => setZoomImage(img)} style={{cursor: 'pointer'}} className={`col-6 col-md-3 px-1 pb-2 ${style.gallery_thumbnail}`}>
                                        <img style={{borderRadius: '3px'}} src={img} width={'100%'} />
                                    </div>
                                )
                            })
                        }
                        </div>
                    </div>
                </div>}
            </div>
        </div>
    )
    else{
        return (
            <div></div>
        )
    }
}

export default SingleCode;
