import React from 'react'
import style from './SingleCodeSidebar.module.css'
import { IoIosTabletPortrait } from "react-icons/io";
import { IoIosPhonePortrait } from "react-icons/io";
import { HiOutlineComputerDesktop } from "react-icons/hi2";
import { MdLaptopMac } from "react-icons/md";
import { PiTelevisionSimple } from "react-icons/pi";
import { CiCircleCheck } from "react-icons/ci";
import Translate from '../Translate/Translate';
import { tech_img } from '../../data/techsImgs';

function SingleCodeSidebar({item}) {
  return (
    <div className={style.sidebar}>
        <div>
            <span><Translate>technical information</Translate></span>
            <div>
              <span><Translate>Availability</Translate></span>
              <div className={style.availableIconRow}>
                {
                  item.availableOn.map((item) => {
                    if(item == 'mobile') return <div className={style.availableIcon}><IoIosPhonePortrait /></div>
                    if(item == 'tablet') return <div className={style.availableIcon}><IoIosTabletPortrait /></div>
                    if(item == 'laptop') return <div className={style.availableIcon}><MdLaptopMac /></div>
                    if(item == 'web') return <div className={style.availableIcon}><HiOutlineComputerDesktop /></div>
                    if(item == 'tv') return <div className={style.availableIcon}><PiTelevisionSimple /></div>
                  })
                }
              </div>
            </div>
            <div style={{marginTop: '5px'}}>
              <span><Translate>LIVE Preview</Translate></span>
              <div className={style.liveRow}>
                {/* {item.url ? <a href={item.url} target='_blank'>{item.url.replace('https://', '').replace('.vercel', '')}</a> : <span style={{opacity: .15}}>Projekt OFFLINE</span> } */}
                {item.url ? <a href={item.url} target='_blank'><Translate>Check out app</Translate></a> : <span style={{opacity: .15}}>Projekt OFFLINE</span> }
              </div>
            </div>
            <div style={{marginTop: '5px'}}>
              <span><Translate>Functionalities</Translate></span>
              <div className={style.feauturesRow}>
                {
                  item.feautures.map((item) => {
                    return (
                      <div>
                        <div className={style.feauturesRowIcon}>
                          <CiCircleCheck />
                        </div>
                        <span>
                          <Translate>{item}</Translate>
                        </span>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div style={{marginTop: '5px'}}>
              <span><Translate>Technologies</Translate></span>
              <div className={style.feauturesRow}>
                {
                  item.stack.map((item) => {
                    return (
                      <div>
                        <div className={style.stackRowIcon}>
                          <img src={tech_img[item]} />
                        </div>
                        <span>
                          <Translate>{item}</Translate>
                        </span>
                      </div>
                    )
                  })
                }
              </div>
            </div>
        </div>
    </div>
  )
}

export default SingleCodeSidebar