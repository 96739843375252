const pl = {

    // navbar.js
    'Experience' : 'Doświadczenie',
    'Design' : 'Grafika',
    'Code' : 'Projekty',
    'Photography' : 'Fotografia',
    'Contact' : 'Kontakt',

    // sidebar.js
    "Name" : "Imię",
    "Surname" : "Nazwisko",
    "Age" : "Wiek",
    "Location" : "Lokalizacja",
    "Poznan, Poland" : "Poznań, Polska",
    "🇵🇱 Poland" : "🇵🇱 Polska",
    "polish, english" : 'polski, angielski',
    'Languages' : 'Języki',
    "Download my CV" : "Pobierz moje CV",

    // home.js
    "What technologies do I use?" : "Jakich technologii używam?",

    // aboutme
    "About me" : "O mnie",
    "What is my business?" : "Czym się zajmuję?",
    "What languages do I know?" : "Jakie znam języki?",
    "Hi 👋 let me tell you a few words about me, I have been developing websites and web applications for over 7 years. I use technologies such as HTML, CSS, Bootstrap, JavaScript, ReactJS among others in my projects. I can create and administer MySQL and Firebase systems, as well as connect between applications via APIs. I carry out all assignments using up-to-date, most optimal technologies for the project, while ensuring cross-platform performance." : "Cześć 👋 pozwól, że opowiem Tobie kilka słów o mnie, zajmuję się tworzeniem stron i aplikacji internetowych od ponad 7 lat. Wykorzystuję w projektach między innymi technologie takie jak HTML, CSS, Bootstrap, JavaScript, PHP, ReactJS. Potrafię stworzyć oraz administrować systemy MySQL i Firebase, a także łączyć się między aplikacjami poprzez API. Wszystkie zlecenia wykonuję przy użyciu aktualnych, najbardziej optymalnych dla danego projektu technologii, przy jednoczesnym zadbaniu o wieloplatformowe działanie.",
    "I also do computer graphics professionally - designing logos, flyers, posters, advertising banners, social media graphics, website visuals and much more, there is no graphic design that is impossible for me. All work is created to the highest quality using current standards." : "Zajmuję się również zawodowo grafiką komputerową – projektowaniem logotypów, ulotek, plakatów, banerów reklamowych, grafik na potrzeby social media, wizualizacji stron internetowych oraz wiele więcej, nie ma dla mnie projektu graficznego, który byłby niemożliwy do wykonania. Wszystkie prace są tworzone w najwyższej jakości przy użyciu aktualnych standardów.",
    "I have been trusted by many companies, people running channels, social media profiles and organisers of various events. If you are looking for a person to whom you want to commission the creation of the whole project from A to Z (visual design, programming, implementation and management), and then receive a ready, fully functioning, modern-looking product, you've come to the right place. I invite you to take a look at my experience, portfolio and contact me via the form to establish the details of cooperation." : "Zaufało mi wiele firm, osób prowadzących kanały, profile w social media oraz organizatorów różnych wydarzeń. Jeżeli szukasz osoby, u której chcesz zlecić stworzenie całego projektu od A do Z (projekt wizualny, zaprogramowanie, wdrożenie oraz zarządzanie nim), a następnie odebrać gotowy, w pełni działający, nowocześnie wyglądający produkt, to dobrze trafiłeś. Zapraszam Cię do zapoznania się z moim doświadczeniem, portfolio oraz kontaktem przez formularz w celu ustalenie szczegółów współpracy.",
    'In addition to programming and computer graphics, I am a hobbyist in photography, mainly drone photography and film editing. A portfolio of my photos can be seen at capturedbyjan.art. Examples of film productions, are available on my YouTube channel.' : "Poza programowaniem i grafiką komputerową od zajmuję się hobbistycznie fotografią, głównie zdjęcia przy użyciu drona oraz montażem filmów. Portfolio moich zdjęć można zobaczyć na stronie capturedbyjan.art. Przykładowe realizacje filmowe, są dostępne na kanale YouTube.",
    "Hi there! 👋 I have been creating modern web applications for over 8 years and working professionally for over 3 years. I pay attention to the smallest details in my projects and use the most optimal solutions during the development process. I work with JavaScript, TypeScript, ReactJS, NextJS, Firebase, and Sanity. I would be happy to complete your project from A to Z - from graphic design to creating a fully functional product and final launch, as well as providing ongoing support. In my free time, I enjoy photography and producing videos using a drone." : "Cześć 👋 Zajmuję się tworzeniem nowoczesnych aplikacji internetowych od ponad 8 lat, w zawodzie pracuję ponad 3 lata. W swoich projektach przykładam uwagę do najmniejszych szczegółów oraz wykorzystuję najbardziej optymalne rozwiązania w procesie ich tworzenia. Pracuję z wykorzystaniem JavaScript, TypeScript, ReactJS, NextJS, Firebase, Sanity. Chętnie wykonam twój projekt od A do Z - od projektu graficznego, przez stworzenie gotowego w pełni działającego produktu, po końcowe uruchomienie go i opiekę nad nim. W czasie wolnym hobbistycznie zajmuję się fotografią i produkcją video przy użyciu drona.",

    // my business
    "Programming" : "Programowanie",
    "Design" : "Grafika",
    "Montage" : "Montaż",
    "Photography" : "Fotografia",
    "Drone Pilot" : "Operator drona",
    "Web applications, websites, online shops, blogs." : "Aplikacje internetowe, strony, sklepy internetowe.",
    "Logos, banners, ads, application interfaces." : "Loga, banery, reklamy, interfejsy aplikacji.",
    "Editing and montage of films, commercials." : "Montaż i edycja filmów, spotów reklamowych.",
    "Drone video and photo" : "Fotografia oraz filmy z drona",

    // skills
    "My stack" : "Technologie, w których pracuję",
    "Soft I am using" : "Programy, których używam",
    "Advanced" : "Zaawansowane",
    "Very good" : "Bardzo dobrze",
    "Good" : "Dobrze",
    "Basics" : "Podstawy",

    // languages
    "Polish" : "Polski",
    "English" : "Angielski",
    "German" : "Niemiecki",
    "Spanish" : "Hiszpański",
    "Native" : "Narodowy",
    "Very good (C1)" : "Bardzo dobrze (C1)",
    "Basics" : "Podstawy",
    "Basics (A1)" : "Podstawy (A1)",

    // design.js
    "Graphics portfolio" : "Portfolio graficzne",
    "Load more" : "Załaduj więcej",
    "Load all" : "Załaduj wszystko",
    'all' : 'wszystko',
    'logo' : 'logo',
    'poster' : 'plakat',
    'leaflet' : 'ulotka',
    'book' : 'książka',
    'banner' : 'baner',
    'business card' : 'wizytówka',
    'snapchat' : 'snapchat',
    'advertisement' : 'reklama',

    // contact.js
    "Write to me" : "Napisz do mnie",
    "Your name" : "Twoje imię",
    "Your mail" : "Twój email",
    "Topic of your message" : "Temat twojej wiadomości",
    "Content of your message" : "Treść twojej wiadomości",
    "Send message" : "Wyślij wiadomość",
    "The message was sent correctly! You have received a copy of it at your email address. I will try to reply to it within 24 hours. Until we hear from you!" : "Wysłano poprawnie wiadomość! Otrzymałeś jej kopię na swój adres email. Postaram się na nią odpowiedzieć w przeciągu 24 godzin. Do usłyszenia!",
    "Sending..." : "Wysyłanie",
    "An error occurred while sending the message. Contact me previous email: jan@mager.tech" : "Wystąpił błąd podczas wysyłania wiadomości. Skontaktuj się ze mną poprzed mail: jan@mager.tech",

    // footer.js
    "Project and realization" : "Projekt i realizacja",

    // experiencestep.js
    "years" : 'lata',
    "month" : "miesiąc",
    "months" : "miesięcy",
    "Application preview" : "Podgląd aplikacji",
    "DEMO of the application" : "DEMO aplikacji",
    "See details" : "Zobacz szczegóły",
    "What technologies were used?" : "Jakie technologie zostały użyte?",

    // code.js
    "Application Portfolio" : "Portfolio aplikacji",
    "View LIVE application" : "Zobacz aplikację LIVE",
    "VIEW APP PREVIEW" : "ZOBACZ PODGLĄD APLIKACJI",
    "More information" : "Więcej informacji",

    "FinanceBay - assistant for your finances" : "FinanceBay - asystent Twoich finansów",
    "A modern application to manage your budget, control all expenses, income, recurring payments, check reports from individual categories. This application will help you manage and save money." : "Nowoczesna aplikacja do zarządzania Twoim budżetem, kontroluj wszystkie wydatki, przychody, płatności cykliczne, sprawdzaj raporty z poszczególnych kategorii. Aplikacja ta pomoże Tobie w zarządzaniu i oszczędzaniu pieniędzmi.",

    "CapturedByJan.art - photography portfolio" : "CapturedByJan.art - portfolio fotograficzne",
    "A private photo portfolio containing a collection of photos and videos (mainly from a drone). Author's blog system using Headless CMS Sanity.io (entering new photos, posts, products to the store), e-commerce system with payment connection using Stripe." : "Prywatne portfolio fotograficzne zawierające zbiór zdjęc i filmów (głównie z drona). Autorski system blogowy przy użyciu Headless CMS Sanity.io (wprowadzanie nowych zdjęć, postów, produktów do sklepu), system ecommerce z podpięciem płatności przy pomocy Stripe.",
    
    "CryptoFolio.io - a portfolio of cryptocurrency transactions" : "CryptoFolio.io - portfolio transkacji kryptowalutami",
    "An application created to manage investments in cryptocurrencies. The system works fully on any system and device. CryptoFolio.io allows you to track current cryptocurrency rates, track profits and losses on the spot market and future positions. The user has full access to transaction history , daily automatic reports sent to e-mail and many other functions." : "Aplikacja stworzona do zarządzania inwestycjami w kryptowaluty. System działa w pełni na każdym system i urządzeniu. CryptoFolio.io pozwala na śledzenie aktualnych kursów kryptowalut, śledzenia zysków i strat na markecie spot oraz pozycji futurues. Użytkownik ma pełny wgląd do historii transakcji, codziennych automatycznych raportów wysyłanych na maila oraz wiele innych funkcji.",
    
    "Typerka - betting on matches" : "Typerka - obstawianie meczów",
    "Internet application (game), created for the purpose of learning ReactJS, Firebase and Node.js. The site allows you to set up an account and later edit it, the user has the option to bet on the exact result of matches for the currently played league. The application works dynamically and is fully responsive. A backend based on Node.js has been built, it checks match results in real time, updates the ranking and sends players notifications about upcoming matches and summarizing the match day." : "Aplikacja internetowa (gra), stworzona w celach nauki ReactJS, Firebase oraz Node.js. Strona pozwala na założenie konta oraz późniejszą jego edycję, użytkownik ma możliwość obstawienia dokładnego wyniku meczy dla aktualnie rozgrywanej ligii. Aplikacja działa w sposób dynamiczny oraz jest w pełni responsywna. Postawiony został backend oparty o Node.js, sprawdza on w czasie rzeczywistym wyniki meczy, aktualizuje ranking oraz wysyła graczom powiadomienia o zbliżających się meczach oraz podsumowujące dzień meczowy.",

    "Web application (game), created using the Next.js framework, Supabase database, Node.js server. Kinde Auth was used for authorization. The website allows you to create an account and later edit it, the user can bet on the exact match result for the currently played Euro 2024. The application works dynamically and is fully responsive. A backend based on Node.js was built, it checks match results in real time, updates the ranking and sends players notifications about upcoming matches and summarizing the match day." : "Aplikacja internetowa (gra), stworzona przy użyciu framework'a Next.js, baza danych Supabase, serwer Node.js. Do autoryzacji wykorzystano Kinde Auth. Strona pozwala na założenie konta oraz późniejszą jego edycję, użytkownik ma możliwość obstawienia dokładnego wyniku meczy dla aktualnie rozgrywanego Euro 2024. Aplikacja działa w sposób dynamiczny oraz jest w pełni responsywna. Postawiony został backend oparty o Node.js, sprawdza on w czasie rzeczywistym wyniki meczy, aktualizuje ranking oraz wysyła graczom powiadomienia o zbliżających się meczach oraz podsumowujące dzień meczowy.",
    
    "My previous portfolio" : "Moje poprzednie portfolio",
    "A static website presenting my person and programming portfolio as well as graphically. The website is fully responsive, very well optimized, it also contains a photo gallery and a contact form." : "Statyczna strona prezentująca moją osobę i portfolio programistyczne jak i graficznie. Strona jest w pełni responsywna, bardzo dobrze zoptymalizowana, zawiera również galerię zdjęć oraz formularz kontaktowy.",
    
    "Primresim - ecommerce" : "Primresim - sklep internetowy",
    "Online store written from scratch using Next.js technology, database operated by Firebase. Payments processed by Stripe. Original design, based on Tailwind.css" : "Sklep internetowy napisany od zera w technologii Next.js, baza danych obsługiwana przez system Firebase. Płatności realizowane przez Stripe. Autorskie design, oparty na Tailwind.css",
    "Next.js is a modern React framework that ensures lightning-fast page loading. Firebase is Google's cloud platform that offers a secure and scalable database and authorization. Stripe - a platform that allows you to easily integrate payments. Tailwind.css allows you to create a responsive and aesthetic design without writing unnecessary code." : "Next.js to nowoczesny framework React, który zapewnia błyskawiczne ładowanie stron. Firebase to platforma chmurowa Google, która oferuje bezpieczną i skalowalną bazę danych i autoryzację. Stripe - platforma, która umożliwia łatwą integrację płatności. Tailwind.css, pozwala na tworzenie responsywnego i estetycznego designu bez pisania zbędnego kodu.",

    "Interactive map with the location of shelters" : "Interaktywna mapa z lokalizacją schronów",
    "Internet application presenting a list of the nearest shelters in Poznań in a clear way for the user in the form of an interactive map. The website was made using the ReactJS framework, works fully on any device and without reloading the page. The user can quickly and conveniently find a shelter and get more information about it (navigation link, shelter capacity)." : "Aplikacja internetowa, prezentująca w czytelny dla użytkownika sposób listę najbliższych schronów w Poznaniu w postaci interaktywnej mapki. Strona została wykonana przy użyciu frameworka ReactJS, działa w pełni na każdym urządzeniu oraz bez przeładowania strony. Użytkownik może szybko i wygodnie znaleźć schron oraz uzyskać o nim więcej informacji (odnośnik do nawigacji, pojemność schronu).",
    
    "Check your negotiating style" : "Sprawdź Twój styl prowadzenia negocjacji",
    "Thomas Kilmann's interactive test, thanks to which you can learn your negotiation style. You will be presented with 30 situations, you have to choose the closest to your actual reaction. Technologies such as PWA (Progressive Web App), Chart.js were used in this project (graphs at the end of the test) and full responsiveness and operation without reloading the page." : "Interaktywny test Thomasa Kilmanna, dzięki któremu możesz poznać swój styl prowadzenia negocjacji. Zostanie Tobie przedstawione 30 sytuacji, musisz wybrać bliżą twojej faktycznej reakcji. Zostały wykorzystane w tym projekcie technologie takie jak: PWA (Progressive Web App), Chart.js (wykresy na końcu testu) oraz pełna responsywność i działanie bez przeładowania strony.",

    "RecipeMe - interactive cookbook" : "RecipeMe - interaktywna książka kucharska",
    "A web application using the API of the recipe database from the Edamam.com website, where we can dynamically search for recipes without reloading the page. The project allows you to select results through filters. The website is adapted to every device." : "Aplikacja internetowa wykorzystująca API bazy przepisów ze strony Edamam.com, możemy na niej dynamicznie bez przeładowania strony wyszukiwać przepisy kucharskie. Projekt umożliwia selekcję wyników poprzez filtry. Strona dostosowana jest na każde urządzenie.",
    
    "SPACER - NASA photo database" : "SPACER - baza zdjęć NASA",
    "A web application using the NASA photo database API, we can dynamically search for a given gallery by entering any word related to space, NASA, galaxy, etc. Without reloading the page, thanks to Vue.JS, we immediately get a photo gallery in the CSS grid with descriptions straight from the National Aeronautics Agency and Outer Space." : "Aplikacja internetowa wykorzystująca API bazy zdjęć NASA, możemy dynamicznie wyszukać daną galerię, wpisując dowolne słowo związane z kosmosem, nasa, galaktyką itp. Bez przeładowania strony dzięki Vue.JS od razu dostajemy w gridzie CSS galerię zdjęc wraz z opisami prosto od Narodowa Agencja Aeronautyki i Przestrzeni Kosmicznej.",

    "NeonKomunikator - text messenger" : "NeonKomunikator - komunikator tekstowy",
    "Text messenger project made with Next.js, TailwindCSS, Prisma, TypeScript. The database of users and conversations is maintained using MongoDB. Registration possible with NextAuth authorization - logging in via Google or Github account." : "Projekt komunikatora tekstowego wykonany przy pomocy Next.js, TailwindCSS, Prisma, TypeScript. Baza danych użytkowników, rozmów obsługiwana jest przy pomocy MongoDB. Rejestracja możliwa przy autoryzacji NextAuth - logowanie poprzez konto Google lub Github.",

    "TradeBest - Convenient Real Estate Service" : "TradeBest - Wygodny Serwis Nieruchomości",
    "A modern, original portal for searching and adding advertisements for the sale and rental of houses, apartments and plots. Portal written using Next.js, based on the Firebase database." : "Nowoczesny autorski portal do wyszukiwania i dodawania ogłoszeń sprzedaży i wynajmu domów, mieszkań i działek. Portal napisany przy użyciu Next.js, oparty o bazę danych Firebase.",
    'movie recommend app' : 'aplikacja do polecania filmów',
    'expense journal' : 'dziennik wydatków',
    "Check app" : "Sprawdź aplikację",
    "finances assistant" : "asystent finansów",
    "photography portfolio" : "portfolio fotograficzne",
    "text messenger" : "komunikator tekstowy",
    "cryptocurrency manager" : "menadżer kryptowalut",
    "mobile game" : "gra mobilna",
    "real estate service" : "portal nieruchomości",
    "Where is Shelter" : "Gdzie jest Schron",
    "interactive map of shelters" : "interaktywna mapa schronów",
    "Test of T.Kilmann\'s" : "Test T.Kilmann\'a",
    "interactive test" : "interaktywny test",
    "culinary recipes" : "przepisy kulinarne",
    "my previous portfolio" : "poprzednie portfolio",
    "NASA photo database" : "Baza zdjęć NASA",
    "buy crypto widget" : "widget zakupu kryptowalut",
    "Check out" : "Sprawdź",
    "Application UI preview" : "Podgląd UI aplikacji",
    'Application live DEMO' : "Live DEMO aplikacji",
    'Web applications, websites, shops' : 'Aplikacje, strony, sklepy internetowe',
    'Logos, banners, ads, application UI' : 'Loga, banery, reklamy, UI/UX',
    "internship, robotics" : "robotyka, staż",
    'app of restaurants' : 'aplikacja dla restauracji',
    'merchant panel' : 'panel handlowca',
    'Application views' : 'Widoki z aplikacji',
    'dynamic charts' : 'dynamiczne wykresy',
    'fetching currency rates api' : 'aktualne kursy walut',
    'auth system' : 'system autoryzacji',
    'dynamic database' : 'baza danych',
    'own api' : 'autorskie API',
    'full RWD' : 'pełna responsywność',
    'LIVE Preview' : 'Podląd LIVE',
    'Check out app' : 'Sprawdź aplikację',
    'technical information' : 'informacje techniczne',
    'Availability' : 'Dostępność',
    'CMS system' : 'system CMS',
    'e-commerce system' : 'system e-commerce',
    'stripe payments' : 'system płatności',
    'dynamic map with pins' : 'dynamiczna mapa',
    'sending emails' : 'wysyłka email',
    'Functionalities' : 'Funkcjonalności',
    'Technologies' : 'Technologie',

    // experience.js
    "Professional experience" : "Doświadczenie zawodowe",
    "cryptocurrency exchange" : "giełda kryptowalut", 
    'roommate app' : 'aplikacja dla współlokatorów',
    "Programming the visual part of the Egera cryptocurrency exchange, the user panel and the company's main website using languages ​​such as: HTML, CSS, PHP, JavaScript, jQuery. Creating advertising graphics and designing application interfaces." : "Programowanie części wizualnej giełdy kryptowalut Egera, panelu użytkowika i głównej strony firmy przy użyciu języków takich jak: HTML, CSS, PHP, JavaScript, jQuery. Tworzenie grafik reklamowych oraz projektowanie interfejsów aplikacji.",
    "June 2021 - Now" : "Czerwiec 2021 - Teraz",
    "Graphic designer" : "Grafik komputerowy",
    "Administering the website of the PixelsInteractive studio that creates computer games. Creating pages and subpages and making corrections to them, modernizing them and adding new functionalities." : "Administrowanie stroną studia PixelsInteractive zajmującego się tworzeniem gier komputerowych. Tworzenie stron i podstron oraz wykonywanie poprawek na nich, modernizacja i dodawanie nowych funkcjonalności.",
    "Creating graphics for social media and marketing materials, working with vector and raster graphics. Design and coding of a landing page, one page, administration of a Wordpress-based website." : "Tworzenie grafiki na potrzeby social media oraz materiałów marketingowych, praca na grafice wektorowej oraz rastrowej. Projektowanie i kodowanie landingpage, onepage, administracja strony opartej na Wordpress.",
    "programming science club" : "programistyczne koło naukowe",
    "Designing graphical interfaces, icons, animations and game elements for the High Score Games student science club at the WSB University in Poznań, dealing with the creation of computer games." : "Projektowanie interfejsów graficznych, ikonek, animacji oraz elementów gry dla studenckiego koła naukowego High Score Games na Wyższej Szkole Bankowej w Poznaniu zajmującego się tworzeniem gier komputerowych.",
    "October 2020 - November 2021" : "Październik 2020 - Listopad 2021",
    "IT Support and Graphic Designer" : "Wsparcie IT i Grafik komputerowy",
    "computer game publisher" : "wydawca gier komputerowych",
    "Administration of the website of the PixelsInteractive studio dealing with the creation of computer games. Creating pages and subpages (landingpage) and then making corrections on them, modernizing, adding new functionalities. Designing graphics for marketing and identification purposes (icons, logos, banners, elements on websites)." : "Administrowanie stroną studia PixelsInteractive zajmującego się tworzeniem gier komputerowych. Tworzenie stron i podstron (landingpage) oraz następnie wykonywanie poprawek na nich, modernizacja, dodawanie nowych funkcjonalności. Projektowanie grafik do celów marketingowych i indentyfikacyjnych (ikony, logo, banery, elementy na stronach www).",
    "April 2020 - September 2020" : "Kwiecień 2020 - Wrzesień 2020",
    "Programmer and Administrator of websites" : "Programista i Administrator stron WWW",
    "robotics" : "robotyka",
    "restaurant system" : "system dla gastronomii",
    "Eatspace - restaurant system" : "Eatspace - system dla gastronomii",
    "EatSpace is the perfect tool for planning unique culinary experiences. Easily book a table at your favorite restaurant, and additionally benefit from the option of choosing dishes, table or payment before arriving." : "EatSpace to idealne narzędzie do planowania wyjątkowych doświadczeń kulinarnych. Z łatwością zarezerwuj stolik w ulubionej restauracji, a dodatkowo skorzystaj z opcji wyboru dań, stolika czy płatności jeszcze przed przyjściem.",
    "Browse an extensive database of restaurants in your region that opens the door to endless culinary adventures. Discover new places and rate restaurants. Book a table in a few moments, without having to call or visit the restaurant in person. Select the date, time, specific table and meals you want to order. Application created during the defense of an engineering thesis during IT studies." : "Przeglądaj bogatą bazę danych restauracji w Twoim regionie, która otwiera drzwi do niekończoncej się przygody kulinarnej. Odkyrwaj nowe miejsca i oceniaj restauracje. Zarezerwuj stolik w kilka chwil, bez konieczności dzwonienia czy osobistej wizyty w restauracji. Wybierz datę, godzinę, konkretny stolik oraz posiłki, które chcesz zamówić. Aplikacja stworzona podczas obrony pracy inżynierskiej podczas studiów informatycznych.",
    "November 2018 - December 2018" : "Listopad 2018 - Grudzień 2018",
    "Installation, configuration and administration of websites based on the Wordpress CMS system and Moodle elearning platform at MalagaByte company located in Malaga, Spain. Searching for errors in the code and applying corrections." : "Instalacja, konfiguracja i administrowanie stron opartych na systemie CMS Wordpress i platformie elearning Moodle w firmie MalagaByte mieszczącej się w Maladze, Hiszpania. Wyszukiwanie błędów w kodzie i nanoszenie poprawek na nie.",
    "Graphic designer and web developer" : "Grafik komputerowy i Programista stron WWW",
    "social media" : "social media",
    "Creating graphics for social media and marketing materials for FirstMilionPrzed30. Work on vector and raster graphics, photo processing, designing book covers, website elements, facebook banners and promotional posters. Designing and coding landingpage, onepage and website administration based on the Wordpress system." : "Tworzenie grafiki na potrzeby social media oraz materiałów marketingowych dla PierwszyMilionPrzed30. Praca na grafice wektorowej oraz rastrowej, obróbka zdjęć, projektowanie okładki książki, elementów na stronę www, banerów facebook oraz plakatów promocyjnych. Projektowanie i kodowanie landingpage, onepage i administracja strony opartej na systemie Wordpress.",
    "July 2017 - November 2021" : "Lipiec 2017 - Listopad 2021",
    "WSB University in Poznań, Poland" : "Wyższa Szkoła Bankowa w Poznaniu, Polska",
    "University WSB Merito in Poznań, Poland" : "Uniwersytet WSB Merito w Poznaniu, Polska",
    "Issued by" : "Wydane przez",
    "Certificates" : "Certyfikaty",
    "Education" : "Edukacja",
    "2024 - now" : '2024 - teraz',
    "Master's degree in management with specialization in Junior Project Manager" : 'Studia magisterskie na kierunku zarządzanie ze specjalizacją Junior Project Manager',
    "2020 - now" : "2020 - teraz",
    'bitcoin atm ui' : 'interfejs bitomatu',
    'translator' : 'tłumacz',
    'In addition, after registration, the user has the ability to track their savings by creating personal piggy banks, analyze expenses over time and by category. There is also an option to track your investments in cryptocurrencies, thanks to automatic download of current exchange rates, we can analyze total profits and losses in a transparent way.' : 'Dodatkowo użytkownik po rejestracji ma możliwość śledzenia swoich oszczędności tworząc personalne skarbonki, analizowania wydatków na przestrzeni czasu oraz z podziałem na kategorie. Istnieje również opcja śledzenia swoich inwestycji w kryptowaluty, dzięki automatycznego pobieraniu aktualnych kursów walut, w przejrzysty sposób możemy analizować całkowite zyski i straty.',
    'Web and mobile application that allows you to track your private budget. The user can register their current expenses, income, overdue payments, automatically checked recurring payments, e.g. for subscriptions to other applications such as Netflix or Spotify.' : 'Aplikacja internetowa i mobilna pozwalająca śledzić swój prywatny budżet. Użytkownik ma możliwość rejestrowania swoich aktualnych wydatków, przychodów, zaległoych płatności, automatycznie sprawdzanych płatności cyklicznych np. za subskrypcje innych aplikacji takich jak Netflix lub Spotify.',
    'Graphic design and coding of the entire flow for the physical interface of the Egera bitcoin bitcoin machine. Interface based on the Chrome engine, adapted pixel perfect for a FullHD ATM display. Dynamic component loading, three language versions, API connection refreshing in the background. Communication with an external printer of sales and purchase confirmations.' : 'Projekt graficzny i zakodowanie całego flow dla interfejsu fizycznego bitomatu bitcoin Egera. Interefejs oparty na silniku Chrome, przystosowany pixel perfect pod wyświetlacz w bankomacie FullHD. Dynamiczne ładowanie komponentów, trzy wersje językowe, odświeżanie w tle połączenia z API. Komunikacja z zewnętrzną drukarką potwierdzeń sprzedaży i zakupów.',
    'Advertising website for the physical Egera Bitcoin Bitomat, the device allows the stationary purchase of various cryptocurrencies for cash, their sale and cash withdrawal. The website is maximally optimized for Google positioning and Google Pagespead Lighthouse.' : 'Reklamowa strona internetowa dla fizycznego Bitomatu Bitcoin Egera, urządzenie umożliwia stacjonarny zakup różnych kryptowalut za gotówkę, ich sprzedaż oraz wypłatę gotówki.  Strona jest maksymalnie zoptymalizowana po pozycjonowanie google oraz Google Pagespead Lighthouse.',
    'A simple translator created for work at Egera. The user can provide the object in the format specified in the instructions, as a result of which he will receive all words and sentences automatically translated into the defined (18) languages ​​in the code. The application was created using the React framework, translation is provided by the Google Translate API service.' : 'Prosty tłumacz zrobiony na potrzeby pracy w firmie Egera. Użytkownik ma możliwość podania obiektu w podanym w instrukcji formacie, w rezultacie otrzyma automatycznie przetłumaczone wszystkie słowa i zdania na zdefiniowane (18) języki w kodzie. Aplikacja wykonana przy pomocy frameworka React, tłumaczenie dostarcza usługa Google Translate API.',
    'user panel' : 'panel użytkownika',
    'Graphic design and layout of the user panel of the Egera cryptocurrency exchange. The customer can quickly deposit and withdraw funds and purchase cryptocurrencies via interactive widgets. The website is fully optimized for full accessibility from all devices.' : 'Projekt graficzny i zakowanie panelu użytkownika giełdy kryptowalut Egera. Klient ma możliwość szybkiej wpłaty oraz wypłaty swoich środków, zakupu kryptowalut poprzez interaktywne widgety. Strona w pełni zoptymalizowana dla pełen dostępności ze wszystkich urządzeń.',
    'website' : 'strona internetowa',
    "Graphic design and coding of the sales page for the Egera cryptocurrency exchange, the entire portal includes a blog, operations on amounts, animations, a language change system, and forms. Site fully optimized for PageSpeed ​​Lighthouse results." : "Projekt graficzny i zakodowanie strony sprzedażowej dla giełdy kryptowalut Egera, cały portal zawiera bloga, operacje na kwotach, animacje, system zmiany języków, formularze. Witryna maksymalnie zoptymalizowana pod kątem wyników PageSpeed Lighthouse.",
    "Engineering studies in the field of computer science with a specialization in application programming" : "Studia inżynierskie na kierunku informatyka ze specjalizacją programowanie aplikacji",
    "IT technician specialization." : "Specjalizacja techink-informatyk.",
    "Communications School Complex in Poznań, Poland" : "Zespół Szkół Łączności w Poznaniu, Polska",
    "E.12 Qualification" : "Kwalifikacja E.12",
    "Installation and operation of personal computers and peripheral devices" : "Montaż i eksploatacja komputerów osobistych oraz urządzeń peryferyjnych",
    "January 2018" : "Styczeń 2018",
    "E.13 Qualification" : "Kwalifikacja E.13",
    "Designing local computer networks and network administration" : "Projektowanie lokalnych sieci komputerowych i administrowanie sieciami",
    "June 2019" : "Czerwiec 2019",
    "E.14 Qualification" : "Kwalifikacja E.14",
    "Creating web applications and databases and administering databases" : "Tworzenie aplikacji internetowych i baz danych oraz administrowanie bazami",
    "June 2020" : "Czerwiec 2020",
    "IT Engineer" : "IT Engineer",
    "Education" : "Wykształcenie"
}

export default pl
